import React, { useEffect, useRef, useState } from 'react';
import  classes from './MySizesPopup.module.css';
import Close from '../icons/close.svg'

const MySizesPopup = ({ onClose }) => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const popupRef = useRef(null);

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
     };

     useEffect(() => {
      const handleKeyDown = (event) => {
          if (event.keyCode === 27) {
              onClose();
          }
      };
  
      const handleClickOutside = (event) => {
          if (popupRef.current && !popupRef.current.contains(event.target)) {
              onClose();
          }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
  
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('keydown', handleKeyDown);
      };
  }, []);

  return (
    <div className={classes.MyPopup__wrapper}>
      <div className={classes.Image_wrappper}>
          <img src={Close} alt="закрыть" onClick={onClose} />
      </div>
      <div className={classes.MyPopup} ref={popupRef}>
        <div className={classes.MyPopup_content}>
            <div className={classes.MyPopup__title}>Nike Men's</div>
                <table className={classes.MyPopup_sizeTable}>
                <thead>
                  <tr>
                    <th>EU</th>
                    <th>UK</th>
                    <th>US</th>
                    <th>CM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>35.5</td>
                    <td>3</td>
                    <td>3.5</td>
                    <td>22.5</td>
                  </tr>
                  <tr>
                    <td>36</td>
                    <td>3.5</td>
                   <td>4</td>
                    <td>23</td>
                 </tr>
                 <tr>
                   <td>36.5</td>
                   <td>4</td>
                   <td>4.5</td>
                   <td>23.5</td>
                 </tr>
                 <tr>
                   <td>37.5</td>
                    <td>4.5</td>
                    <td>5</td>
                    <td>23.5</td>
                  </tr>
                  <tr>
                    <td>38</td>
                    <td>5</td>
                    <td>5.5</td>
                    <td>24</td>
                  </tr>
                  <tr>
                    <td>38.5</td>
                    <td>5.5</td>
                    <td>6</td>
                    <td>24</td>
                  </tr>
                  <tr>
                    <td>39</td>
                    <td>6</td>
                    <td>6.5</td>
                    <td>24.5</td>
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>6</td>
                    <td>7</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <td>40.5</td>
                     <td>6.5</td>
                     <td>7.5</td>
                     <td>25.5</td>
                   </tr>
                   <tr>
                     <td>41</td>
                     <td>7</td>
                     <td>8</td>
                     <td>26</td>
                   </tr>
                   <tr>
                     <td>42</td>
                     <td>7.5</td>
                     <td>8.5</td>
                     <td>26.5</td>
                   </tr>
                   <tr>
                     <td>42.5</td>
                     <td>8</td>
                     <td>9</td>
                     <td>27</td>
                   </tr>
                   <tr>
                     <td>43</td>
                     <td>8.5</td>
                     <td>9.5</td>
                     <td>27.5</td>
                   </tr>
                   <tr>
                     <td>44</td>
                     <td>9</td>
                     <td>10</td>
                     <td>28</td>
                   </tr>
                   <tr>
                     <td>44.5</td>
                     <td>9.5</td>
                     <td>10.5</td>
                     <td>28.5</td>
                   </tr>
                   <tr>
                     <td>45</td>
                     <td>10</td>
                     <td>11</td>
                     <td>29</td>
                   </tr>
                   <tr>
                     <td>45.5</td>
                     <td>10.5</td>
                     <td>11.5</td>
                     <td>29.5</td>
                   </tr>
                   <tr>
                     <td>46</td>
                     <td>11</td>
                     <td>12</td>
                     <td>30</td>
                   </tr>
                   <tr>
                     <td>47</td>
                     <td>11.5</td>
                     <td>12.5</td>
                     <td>30.5</td>
                      </tr>
                     <tr>
                        <td>47.5</td>
                     <td>12</td>
                     <td>13</td>
                     <td>31</td>
                    </tr>
                 </tbody>
                </table>
                <div className={classes.MyPopup__title}>Nike Women's</div>
                    <table className={classes.MyPopup_sizeTable}>
                        <thead>
                            <tr>
                                <th>EU</th>
                                <th>UK</th>
                                <th>US</th>
                                <th>CM</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>36</td>
                                <td>3</td>
                                <td>5.5</td>
                                <td>22.5</td>
                            </tr>
                            <tr>
                                <td>36.5</td>
                                <td>3.5</td>
                                <td>6</td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <td>37.5</td>
                                <td>4</td>
                                <td>6.5</td>
                                <td>23.5</td>
                            </tr>
                            <tr>
                                <td>38</td>
                                <td>4.5</td>
                                <td>7</td>
                                <td>24</td>
                            </tr>
                            <tr>
                                <td>38.5</td>
                                <td>5</td>
                                <td>7.5</td>
                                <td>24.5</td>
                            </tr>
                            <tr>
                                <td>39</td>
                                <td>5.5</td>
                                <td>8</td>
                                <td>25</td>
                            </tr>
                            <tr>
                                <td>40</td>
                                <td>6</td>
                                <td>8.5</td>
                                <td>25.5</td>
                            </tr>
                            <tr>
                                <td>40.5</td>
                                <td>6.5</td>
                                <td>9</td>
                                <td>26</td>
                            </tr>
                            <tr>
                                <td>41</td>
                                <td>7</td>
                                <td>9.5</td>
                                <td>26.5</td>
                            </tr>
                            <tr>
                                <td>42</td>
                                <td>7.5</td>
                                <td>10</td>
                                <td>27</td>
                            </tr>
                            <tr>
                                <td>42.5</td>
                                <td>8</td>
                                <td>10.5</td>
                                <td>27.5</td>
                            </tr>
                            <tr>
                                <td>43</td>
                                <td>8.5</td>
                                <td>11</td>
                                <td>28</td>
                            </tr>
                            <tr>
                                <td>44</td>
                                <td>9</td>
                                <td>11.5</td>
                                <td>28.5</td>
                            </tr>
                            <tr>
                                <td>44.5</td>
                                <td>9.5</td>
                                <td>12</td>
                                <td>29</td>
                            </tr>
                            <tr>
                                <td>45</td>
                                <td>10</td>
                                <td>12.5</td>
                                <td>29.5</td>
                            </tr>
                            <tr>
                                <td>45.5</td>
                                <td>10.5</td>
                                <td>13</td>
                                <td>30</td>
                            </tr>
                            <tr>
                                <td>47</td>
                                <td>11</td>
                                <td>13.5</td>
                                <td>30.5</td>
                            </tr>
                            <tr>
                                <td>47.5</td>
                                <td>11.5</td>
                                <td>14</td>
                                <td>31</td>
                            </tr>
                        </tbody>
                    </table>
                <div className={classes.MyPopup__title}>New Balance Men's</div>
                    <table className={classes.MyPopup_sizeTable}>
                        <thead>
                            <tr>
                                <th>EU</th>
                                <th>UK</th>
                                <th>US</th>
                                <th>CM</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>39.5</td>
                            <td>6</td>
                            <td>6.5</td>
                            <td>24.5</td>
                        </tr>
                        <tr>
                            <td>40</td>
                            <td>6.5</td>
                            <td>7</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>40.5</td>
                            <td>7</td>
                            <td>7.5</td>
                            <td>25.5</td>
                        </tr>
                        <tr>
                            <td>41.5</td>
                            <td>7.5</td>
                            <td>8</td>
                            <td>26</td>
                        </tr>
                        <tr>
                            <td>42</td>
                            <td>8</td>
                            <td>8.5</td>
                            <td>26.5</td>
                        </tr>
                        <tr>
                            <td>42.5</td>
                            <td>8.5</td>
                            <td>9</td>
                            <td>27</td>
                        </tr>
                        <tr>
                            <td>43</td>
                            <td>9</td>
                            <td>9.5</td>
                            <td>27.5</td>
                        </tr>
                        <tr>
                            <td>44</td>
                            <td>9.5</td>
                            <td>10</td>
                            <td>28</td>
                        </tr>
                        <tr>
                            <td>44.5</td>
                            <td>10</td>
                            <td>10.5</td>
                            <td>28.5</td>
                        </tr>
                        <tr>
                            <td>45</td>
                            <td>10.5</td>
                            <td>11</td>
                            <td>29</td>
                        </tr>
                        <tr>
                            <td>45.5</td>
                            <td>11</td>
                            <td>11.5</td>
                            <td>29.5</td>
                        </tr>
                        <tr>
                            <td>46.5</td>
                            <td>11.5</td>
                            <td>12</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>47.5</td>
                            <td>12.5</td>
                            <td>13</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>49</td>
                            <td>13.5</td>
                            <td>14</td>
                            <td>32</td>
                        </tr>
                        </tbody>
                    </table>
                <div className={classes.MyPopup__title}>New Balance Women's</div>
                    <table className={classes.MyPopup_sizeTable}>
                        <thead>
                            <tr>
                                <th>EU</th>
                                <th>UK</th>
                                <th>US</th>
                                <th>CM</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>35</td>
                            <td>3</td>
                            <td>5</td>
                            <td>22</td>
                        </tr>
                        <tr>
                            <td>36</td>
                            <td>3.5</td>
                            <td>5.5</td>
                            <td>22.5</td>
                        </tr>
                        <tr>
                            <td>36.5</td>
                            <td>4</td>
                            <td>6</td>
                            <td>23</td>
                        </tr>
                        <tr>
                            <td>37</td>
                            <td>4.5</td>
                            <td>6.5</td>
                            <td>23.5</td>
                        </tr>
                        <tr>
                            <td>37.5</td>
                            <td>5</td>
                            <td>7</td>
                            <td>24</td>
                        </tr>
                        <tr>
                            <td>38</td>
                            <td>5.5</td>
                            <td>7.5</td>
                            <td>24.5</td>
                        </tr>
                        <tr>
                            <td>39</td>
                            <td>6</td>
                            <td>8</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>40</td>
                            <td>6.5</td>
                            <td>8.5</td>
                            <td>25.5</td>
                        </tr>
                        <tr>
                            <td>40.5</td>
                            <td>7</td>
                            <td>9</td>
                            <td>26</td>
                        </tr>
                        <tr>
                            <td>41</td>
                            <td>7.5</td>
                            <td>9.5</td>
                            <td>26.5</td>
                        </tr>
                        <tr>
                            <td>41.5</td>
                            <td>8</td>
                            <td>10</td>
                            <td>27</td>
                        </tr>
                        <tr>
                            <td>42.5</td>
                            <td>8.5</td>
                            <td>10.5</td>
                            <td>27.5</td>
                        </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    </div>
  );
};

export default MySizesPopup;
