import React  from 'react';

const NextArrow = (props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="27" viewBox="0 0 42 27" fill="none">
      <path d="M41.1665 13.2645C41.1408 12.9773 41.0205 12.5939 40.828 12.3789L30.6717 0.909035C30.2287 0.3097 29.3152 0.311672 28.7531 0.801842C28.1909 1.29208 28.148 2.16296 28.6405 2.68012L36.829 11.9151L1.89566 11.9151C1.14782 11.9151 0.541492 12.5192 0.541492 13.2645C0.541492 14.0097 1.14782 14.6139 1.89566 14.6139L36.829 14.6139L28.6405 23.8488C28.1031 24.3659 28.2543 25.2527 28.8164 25.7429C29.3786 26.233 30.2287 26.1981 30.6717 25.6199L40.828 14.15C41.0994 13.857 41.1512 13.539 41.1665 13.2645Z" fill="#AC8BD5"/>
    </svg>
  </div>
);

export default NextArrow;